import React, { useEffect } from 'react';
import SEO from '../components/seo';
import Title from '../components/shared/Title';
import { useCart } from '../contexts/cart-context';
import '../styles/pages/success.scss';

const Success = () => {
  const cart = useCart();

  useEffect(() => {
    const isCheckout = localStorage.getItem('checkout');

    if (isCheckout) {
      cart.clear();
      localStorage.removeItem('checkout');
    }
  }, [cart]);

  return (
    <>
      <SEO title='Success' />

      <div className='text-italic'>
        <Title>Checkout</Title>
        <Title type='secondary'>Success</Title>
      </div>
      <div className='success__content'>
        <span role='img' aria-label='Checkmark'>
          ✅
        </span>
      </div>
    </>
  );
};

export default Success;
